html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.goog-inline-block {
  position: relative;
  display: -moz-inline-box;
  display: inline-block;
}
.gb-star-on, .gb-star-off, .gb-star-half {
  height: 13px;
  overflow: hidden;
  width: 13px;
}
.gb-star-on {
  background: no-repeat url(https://books.google.com.eg/googlebooks/images/stars/gb-stars-sprite-1.png) 0 -39px;
}
.gb-star-off {
  background: no-repeat url(https://books.google.com.eg/googlebooks/images/stars/gb-stars-sprite-1.png) 0 -26px;
}
.gb-star-half {
  background: no-repeat url(https://books.google.com.eg/googlebooks/images/stars/gb-stars-sprite-1.png) 0 -13px;
}
