html,
body,
.root {
  height: 100%;
}

body {
  line-height: 1.5;
}

body,
.app {
  background: white;
}

/* main page */

.list-books-title {
  padding: 10px 0;
  background: #251e17;
  text-align: center;
}

.list-books-title span {
  font-size: 21pt;
  font-weight: 400;

  margin-left: 13px;
}

.list-books-title h1,
.list-books-title h2 {
  font-size: 45pt;
  font-family: 'Tangerine', cursive;
  text-align: center;
  margin: 0;
  background: url(/static/media/gold.03b48694.png) center;
  background-size: contain;
  margin: auto;
  width: 75vw;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 1px 1px 8px #ffffff0d;
}

.list-books-content {
  padding: 0 0 80px;
  flex: 1 1;
}

.bookshelf {
  padding: 0 10px 20px;
}

@media (min-width: 600px) {
  .bookshelf {
    padding: 0 20px 40px;
  }
}

.bookshelf-title {
  border-bottom: 1px solid #dedede;
}

.bookshelf-books {
  text-align: center;
}

.open-search {
  position: fixed;
  right: 25px;
  bottom: 25px;
}

.open-search button {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #2e7d32;
  background-image: url(/static/media/add.75d390e5.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-size: 0;
  outline: 0;
}

.open-search button:hover {
  background-color: rgb(0, 102, 0);
}


/* search page */

.search-books-bar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
}

.info-books-bar {
  background-color: #eee;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
}

.search-books-input-wrapper {
  flex: 1 1;
  background: #e9e;
}

.search-books-bar input {
  width: 100%;
  padding: 15px 10px;
  font-size: 1.25em;
  border: none;
  outline: none;
}

.close-search {
  display: block;
  top: 20px;
  left: 15px;
  width: 50px;
  height: 53px;
  background-image: url(/static/media/arrow-back.20e8847d.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 28px;
  font-size: 0;
  border: none;
  outline: none;
}

.close-info {
  display: block;
  top: 20px;
  left: 15px;
  width: 50px;
  height: 53px;
  background-image: url(/static/media/arrow-back.20e8847d.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 28px;
  font-size: 0;
  border: none;
  outline: none;
}

#bookinfo {
  padding: 15px;
}

.booktitle {
  margin-top: 0;
  margin-bottom: 21px;
}

.bookcover {
  float: left;
  margin-right: 12px;
}

.bookinfo_sectionwrap {
  margin-bottom: 2px;
  color: #777;
}

#synopsis {
  padding-right: 80px;
  padding-left: 141px;
}

button:active {
  border: red;
  outline: none;
}

.search-books-results {
  padding: 80px 10px 20px;
}

/* books grid */

.books-grid {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.books-grid li {
  padding: 10px 15px;
  text-align: left;
}

.book {
  width: 140px;
}

.book-title,
.book-authors {
  font-size: 0.8em;
}

.book-title {
  margin-top: 10px;
}

.book-authors {
  color: #999;
}

.book-top {
  position: relative;
  height: 200px;
  display: flex;
  align-items: flex-end;
}

.book-shelf-changer {
  position: absolute;
  right: 0;
  bottom: -10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #5d3f22;
  background-image: url(/static/media/arrow-drop-down.9d4e3f36.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.book-shelf-changer select {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* book cover */

.book-cover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background: #eee;
}

.book-cover-title {
  padding: 20px 10px 0;
  text-align: center;
  font-size: 0.8em;
}

.read-now {
  color: #2196f3;
  text-underline-position: under;
  text-underline-offset: 1px;
}

.book-top .book-cover-overlay {
  width: 128px;
  height: 192px;
  background-color: none;
  position: absolute;
  top: 8px;
  left: 0;
  background-color: rgb(0 0 0 / 90%);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  opacity: 0;
}

.book-top a:hover .book-cover-overlay {

  opacity: 1;

}

.book-top a .book-cover-overlay span {
  color: #dedede;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-transform: uppercase;
  font-weight: bold;

}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  min-width: 250px;
  /* Set a default minimum width */
  margin-left: -125px;
  /* Divide value of min-width by 2 */
  background-color: #333;
  /* Black background color */
  color: #fff;
  /* White text color */
  text-align: center;
  /* Centered text */
  border-radius: 2px;
  /* Rounded borders */
  padding: 16px;
  /* Padding */
  position: fixed;
  /* Sit on top of the screen */
  z-index: 1;
  /* Add a z-index if needed */
  left: 50%;
  /* Center the snackbar */
  bottom: 30px;
  /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

a {
  color: #2196f3;

}

.lds-dual-ring,
.lds-dual-ring:after {
  box-sizing: border-box;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#loading {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000f7;
  z-index: 999999999;
}

#loading .lds-dual-ring {
  display: block;
  width: 208px;
  height: 208px;
  margin: 20% auto;
  color: #999;
}

#loading .lds-dual-ring:after {
  width: 200px;
  height: 200px;
  border: 21.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
  animation: lds-dual-ring 1.2s linear infinite;
}

#loading.show {
  display: block;
}
span.shelf-lable {
  position: absolute;
  top: 0;
  padding: 5px;
  color: #fff;
  transform: translate(-25px,18px);
  -webkit-transform: translate(-25px,18px);
}
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.goog-inline-block {
  position: relative;
  display: -moz-inline-box;
  display: inline-block;
}
.gb-star-on, .gb-star-off, .gb-star-half {
  height: 13px;
  overflow: hidden;
  width: 13px;
}
.gb-star-on {
  background: no-repeat url(https://books.google.com.eg/googlebooks/images/stars/gb-stars-sprite-1.png) 0 -39px;
}
.gb-star-off {
  background: no-repeat url(https://books.google.com.eg/googlebooks/images/stars/gb-stars-sprite-1.png) 0 -26px;
}
.gb-star-half {
  background: no-repeat url(https://books.google.com.eg/googlebooks/images/stars/gb-stars-sprite-1.png) 0 -13px;
}

